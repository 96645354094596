import Homepage from "./pages/homepage";
import { useState } from 'react'
import Header from './components/header'
import Subjects from './pages/subjectspage';

export default () => {

  const [page, setPage] = useState(0);


  return (

    <div className="App">
      <Header setPage={setPage}/>

      { 
        page===1 ? <Subjects/> : <Homepage/>
      }
      
    
    </div>
  )
}