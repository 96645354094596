import { PageGenerator } from './subjects';
import { useState } from 'react'


const ChooseSubjectOption = ({ setSubject }) => {
    return (

        <div className="column">

            <h1>Courses we offer</h1>

            <button className="present_button"onClick={()=>setSubject('maths')}>Maths</button>
            <button className="present_button"onClick={()=>setSubject('english')}>English</button>
            <button className="present_button"onClick={()=>setSubject('science')}>Science</button>
            <button className="present_button"onClick={()=>setSubject('languages')}>Languages</button>
            <button className="present_button"onClick={()=>setSubject('humanities')}>Humanities</button>
            <button className="present_button"onClick={()=>setSubject('psychology')}>Psychology</button>

        </div>

    )
}

export default () => {

    const [ subject, setSubject ] = useState(null)

    return (
        <div className="margintop3 page-centered">
            { subject ? <PageGenerator returnToSubjects={setSubject} location={subject} /> : <ChooseSubjectOption setSubject={setSubject}/>  }
        </div>
    )
}