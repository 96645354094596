import '../../style/css/header.css';
import Loading from '../animations/Loading';
export default ({ setPage }) => {

  return (
      <header className="row spaceinbetween verticalalign">

        <div className="row onegap">
          <Loading/>
          <h1 className="midweighttext">Enmaths</h1>
        </div>

        <div className="row onegap">

          <p onClick={()=>setPage(0)} className="header-links">Home</p>          
          <p onClick={()=>setPage(1)} className="header-links">Subjects</p>          

        </div>


      </header>
  );
}

