import applied_maths_icon from '../../media/subject_icons/applied_maths_icon.png';
import english_icon from '../../media/subject_icons/english_icon.png';
import humanities_icon from '../../media/subject_icons/humanities_icon.png';
import languages_icon from '../../media/subject_icons/languages_icon.png';
import maths_icon from '../../media/subject_icons/maths_icon.png';
import psychology_icon from '../../media/subject_icons/psychology_icon.png';
import science_icon from '../../media/subject_icons/science_icon.png';

export const Subjects = () => {

    let data = [
        { img:maths_icon, title:"MATHS", text:"This is a description of the following subject. Write whatever you wish here." },
        { img:english_icon, title:"ENGLISH", text:"This is a description of the following subject. Write whatever you wish here." },
        { img:humanities_icon, title:"HUMANITIES", text:"This is a description of the following subject. Write whatever you wish here." },
        { img:psychology_icon, title:"PSYCHOLOGY", text:"This is a description of the following subject. Write whatever you wish here." },
        { img:languages_icon, title:"LANGUAGES", text:"This is a description of the following subject. Write whatever you wish here." },
        { img:science_icon, title:"SCIENCE", text:"This is a description of the following subject. Write whatever you wish here." },
    ]

    let applied = { img:applied_maths_icon, title:"Applied Maths", text:"Start coding with your favorite language on any platform, OS, and device." }
         
    let sections = [];

    data.forEach(subject=>
    
        sections.push(<Subjects_mini data={subject}/>)

    )

    sections.push(<div className="blank"></div>);
    sections.push(<Subjects_mini data={applied}/>);
    sections.push(<div className="blank"></div>);


    return (
        <div className="board-component-main">
            <div className="component-header-title">Subjects</div>
            <div id="subjects-inner-container">
                {sections}
            </div>
        </div>
    )
}

const Subjects_mini = ({ data, show=false }) => {
    
    const { img, title, text } = data;

    return (
        <div id="subjects-container-mini">
            <img src={img }/>
            <h2>{title}</h2>
            { show? <p id="subjects-description">{text}</p> : null}
        </div>
    )
}