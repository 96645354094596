import '../../style/css/logo.css'

export default () => {
    return (
        <div class="lds-grid">
        
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        
        </div>        
    )
}