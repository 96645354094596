import stars_ from '../../media/icons/stars.svg';
import arrow from '../../media/icons/arrow.svg';

const currenttestimonials = [
    {
        name: 'Ingrid',
        comment: `Mr. Christian is a excellent math teacher, I recruited him when my son was struggling in maths he was highly recommended by a friend. 
        Today my son is very confident in doing maths in school. His grades has improved and his  confidence doing the subject. Thanks to Mr.Christian's hard work.`,
        date: '21/11/21',
        stars:5 
    },
    {
        name: 'Former Student',
        comment: `Mr Christian is a phenomenal Mathematics teacher! As a former student of his, I have gained a great depth of knowledge which has positively impacted my long term memory. As a result of this, I am now able to transfer the skills learnt from him and pass them on to my current students.`,
        date: '21/11/21',
        stars:5 
    },  
    {
        name: 'Former Student',
        comment: `Mr Christian's Maths lessons have been extremely useful to me for the past 3 years. They have helped me to gain a better understanding of maths nd helped me achieve a grade 9 at GCSE. I would definitely recommend Mr Christian as a maths tutor.`,
        date: '21/11/21',
        stars:5 
    },                
]


export const Testimonials = ({ no_border }) => {
    return (
        <div className="board-component-main">
            <div className="component-header-title">Testimonials</div>
        <div className={no_border ? "regular-cont-main-frame-no-b":"regular-cont-main-frame"}>


            { currenttestimonials.map(testi=><TesteMini dont_show_arrow={true} data={testi}/>) }


        </div>
        </div>
    )
}

const TesteMini = ({ dont_show_arrow, data }) => {
    

    
    return(

        <div className="cats-mini-container-prime">

            <div id="cats-mini-container">
                

                {

                    data!==undefined && 
                    <div  className="testim-mini-container">
                            
                    <p className="testim-mini-name">{data.name}</p>
                    <p className="testim-mini-date">{data.date}</p>
                    <p className="textim-mini-comment">{data.comment}</p>
                    <img src={stars_}/>

                    </div>

                }


            </div>
            {
                dont_show_arrow ? null : <img className="arrow-icon" src={arrow}/>
            }
            

        </div>
    )
}