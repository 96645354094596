import '../../style/css/oldhomepage.css';

export const OldSchool = () => {
    return (
        <div className="board-component-main">
            <div className="component-header-title">Mr Christian's old school</div>
            <div className="youtube-video-container" >
                <iframe width="560" height="600" src="https://www.youtube.com/embed/10ppVom_SoQ?controls=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe width="560" height="600" src="https://www.youtube.com/embed/udm1zcgGGRA?controls=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    )
}