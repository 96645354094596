const CategsMini = () => {
    
    const cats = [
        {
            name: 'A-Levels',
            range: '16-18',
            background:"#41B8DE"
        },
        {
            name: 'GCSE',
            range: '12-16',
            background:"#41DEA5"
        },
        {
            name: 'I-GCSE',
            range: '12-16',
            background:"#607C72"
        },
        {
            name: 'The 11+',
            range: '07-11',
            background:"#DE41C5"
        },                        
    ]

    return(
        <div id="cats-mini-container">
            
            {cats.map(({name, range, background})=>
                
                    <div  className="cats-mini-section">
                        
                        <h3 style={{color:background}} className="cats-mini-name">{name}</h3>
                        <p style={{background}} className="cats-mini-range">{range}</p>

                    </div>
                
                )}

        </div>
    )
}

export const Categories = () => {
    return (
        <div className="board-component-main">
            <div className="component-header-title">Qualifications</div>

        <div className="regular-cont-main-frame">
            {/* <Slider/> */}

            <h2 id="intro-text-b" className="intro-text">
            We offer a fully  personalised tutoring service designed for the UK curriculum.
            </h2>


            <CategsMini/>

        </div>
        </div>
    )
}

