import '../../style/css/oldhomepage.css';
import '../../style/css/image-slider.css';
// import { useDispatch}  from 'react-redux';
import { useState, useEffect }  from 'react';
import rightArrow from '../../media/icons/right-arrow.png';
import leftArrow from '../../media/icons/left-arrow.png';
import pic1 from '../../media/backgrounds/pexels-photo-1.jpeg';
import pic2 from '../../media/backgrounds/pexels-photo-2.jpeg';
import pic3 from '../../media/backgrounds/pexels-photo-3.jpeg';
import pic4 from '../../media/backgrounds/pexels-photo-4.jpeg';
import pic5 from '../../media/backgrounds/pexels-photo-5.jpeg';

const images = [
  pic5, pic1, pic2, pic3, pic4, 
]

export const Introduction = () => {
    
    const [current, setCurrent] = useState(0)
    const [add, set_add] = useState(true);

    const handleClick = (forced=false, forward=false, selective=undefined) => {
        let val = 0;

        if (forced){ 

            if (current==0 && !forward) val = images.length-1;
            else val = (current + (forward ? 1:-1)) % images.length;

            setCurrent(val);
            return;
        }

        if (selective!=undefined) val=selective;
        else val = (current + (add ? 1:-1)) % images.length;
        
        setCurrent(val);
        if (val==(images.length-1)) set_add(false);
        if (val==0) set_add(true);
    }
  
    useEffect(() => {
      const timer = setInterval(() => {
  
        handleClick()
      }, 4000);
      return () => clearTimeout(timer);
    });

    // const dispatch = useDispatch();
    
    return (
        <div id="upper-image">
            <div id="intro-first">
              <IntroSliderBackground images={images} current={current}/>
              <div  className="introduction-containerV2">

                      
                      <div className="introduction-containerV3">
                          <h1 id="intro-tag-header">
                          Easy online learning, just for you.
                          </h1>

                          <p id="welcome-sentence-mini">
                          We offer one-to-one help to students in the UK lessons so they can understand the world around them better.
                          </p>

                          {/* <button onClick={()=>dispatch({type:"TOGGLE_COVER", to:0})} id="start-now">Start now</button> */}

                          <SliderTracker current={current} handleClick={handleClick} size={images.length}/>

                      </div>
              </div>
            </div>
        </div>
    )
}

const IntroSliderBackground = ({ current, images }) => {



    return (
        <div id="background-slider-container">
            <div id="background-slider-original">
                <Slider images={images} current={current}/>
            </div>
            <div id="background-slider-filter"></div>
        </div>        
    )
}

const SliderTracker = ({ size, current, handleClick }) => {
    
    let circles = [];
    for (let a = 0; a < size; a++){
        circles.push(<div key={a} 
            style={{
                border:`3px solid rgba(255, 255, 255, ${current==a? 0.7 : 0.3})`}}
            className="circles"
            onClick={()=>handleClick(false, false, a)}
            ></div>);
    }

    
    return (
        <div id="tracker-container">
            {/* <button onClick={()=>handleClick(true, false)}>Back</button> */}
            <img  className="arrows" src={leftArrow} onClick={()=>handleClick(true, false)}/>
            { 
                circles
            }
            <img  className="arrows" src={rightArrow} onClick={()=>handleClick(true, true)}/>
            
        </div>
    )
}

const Slider = ({ current, images, height }) => {

    const setInitialSlides = () => {
      let slides = []
      images.forEach((url, i)=>{
        slides.push(<SliderContent display={i==current} width={window.innerWidth} height={height} img_src={url}/>);
      })  
      setSlides(slides);
    }
  
    const [slides, setSlides] = useState([]);
  
    useEffect(()=>{
      setInitialSlides();
    }, [])
    
    const styles = { 
      transform: `translate(-${current*window.innerWidth}px, ${0}px)`, 
      transition: "1s",
      display: "flex",
      flexDirection: "row",
    };
  
    return (
      <div id="slider-container"   >
        <div style={styles}>
        {slides}
        </div>
      </div>
    )
}
  
const SliderContent = ({ img_src, display, width, height }) => {
    return <img style={{display, width, height}} className="full-image" src={img_src}/> 
}