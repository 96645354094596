import '../style/css/Home.css';

import { Introduction } from '../components/slider/';
import { OldSchool } from '../components/videos/youtube';
import { Testimonials } from '../components/testimonials'
import { Categories } from '../components/information_panels/Categories'
import { Subjects } from '../components/information_panels/Subjects'

export default () => {




  return (
    <>

      <Introduction/>
      <OldSchool/>
      <Categories/>
      <Subjects/>
      <Testimonials/>
    
    </>
  );
}

